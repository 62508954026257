// Copyright Epic Games, Inc. All Rights Reserved.

interface ICodeToKeyCode {
    [key: string]: number;
}

/*
 * New browser APIs have moved away from KeyboardEvent.keyCode to KeyboardEvent.Code.
 * For details see: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode#constants_for_keycode_value
 * We still use old KeyboardEvent.keyCode integers in the UE C++ side, so we need a way to map the new
 * string-based KeyboardEvent.Code to the old integers.
 */
export const CodeToKeyCode: ICodeToKeyCode = Object.freeze({
    Escape: 27,
    Digit0: 48,
    Digit1: 49,
    Digit2: 50,
    Digit3: 51,
    Digit4: 52,
    Digit5: 53,
    Digit6: 54,
    Digit7: 55,
    Digit8: 56,
    Digit9: 57,
    Minus: 173,
    Equal: 187,
    Backspace: 8,
    Tab: 9,
    KeyQ: 81,
    KeyW: 87,
    KeyE: 69,
    KeyR: 82,
    KeyT: 84,
    KeyY: 89,
    KeyU: 85,
    KeyI: 73,
    KeyO: 79,
    KeyP: 80,
    BracketLeft: 219,
    BracketRight: 221,
    Enter: 13,
    ControlLeft: 17,
    KeyA: 65,
    KeyS: 83,
    KeyD: 68,
    KeyF: 70,
    KeyG: 71,
    KeyH: 72,
    KeyJ: 74,
    KeyK: 75,
    KeyL: 76,
    Semicolon: 186,
    Quote: 222,
    Backquote: 192,
    ShiftLeft: 16,
    Backslash: 220,
    KeyZ: 90,
    KeyX: 88,
    KeyC: 67,
    KeyV: 86,
    KeyB: 66,
    KeyN: 78,
    KeyM: 77,
    Comma: 188,
    Period: 190,
    Slash: 191,
    ShiftRight: 253,
    AltLeft: 18,
    Space: 32,
    CapsLock: 20,
    F1: 112,
    F2: 113,
    F3: 114,
    F4: 115,
    F5: 116,
    F6: 117,
    F7: 118,
    F8: 119,
    F9: 120,
    F10: 121,
    F11: 122,
    F12: 123,
    Pause: 19,
    ScrollLock: 145,
    NumpadDivide: 111,
    NumpadMultiply: 106,
    NumpadSubtract: 109,
    NumpadAdd: 107,
    NumpadDecimal: 110,
    Numpad9: 105,
    Numpad8: 104,
    Numpad7: 103,
    Numpad6: 102,
    Numpad5: 101,
    Numpad4: 100,
    Numpad3: 99,
    Numpad2: 98,
    Numpad1: 97,
    Numpad0: 96,
    NumLock: 144,
    ControlRight: 254,
    AltRight: 255,
    Home: 36,
    End: 35,
    ArrowUp: 38,
    ArrowLeft: 37,
    ArrowRight: 39,
    ArrowDown: 40,
    PageUp: 33,
    PageDown: 34,
    Insert: 45,
    Delete: 46,
    ContextMenu: 93
});
